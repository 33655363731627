$gold: #8B7867;
$red: rgb(170, 25, 28);
/* open-sans-regular - latin */
$phonewidth: 600px;
$mobilewidth: 750px;
$tabletwidth: 1250px;
$laptopwidth: 1440px;
$font: Georgia, Avenir, Helvetica, Arial, sans-serif;
$headfont: 'Benchnine', Avenir, Helvetica, Arial, sans-serif;
$grid-breakpoints: (
        xs: 0,
        sm: $phonewidth,
        md: $mobilewidth,
        lg: $tabletwidth,
        xl: $laptopwidth
);


@mixin mobile {
  @media (max-width: #{$mobilewidth}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: "#{$phonewidth}") {
    @content;
  }
}

@mixin landscape {
  @media (max-width: "#{$phonewidth}") and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tabletwidth}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$laptopwidth}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: "#{$phonewidth}") {
    @content;
  }
}

@mixin touch {
  @media (hover: none) {
    @content;
  }
}

.nomobile {
  @include mobile {
    display: none!important;
  }
}

.onlymobile {
  @include desktop {
    display: none!important;
  }
}