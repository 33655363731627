@import "src/custom.scss";































































































































































































.home {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: $gold;
  //max-height: 100vh;
  //height: 100vh;

}

.overlay {
  .start {

    overflow: hidden;
  }
}

.down {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: transparent;
  //position: absolute;
  a {
    &.invisible {
      opacity: 0.3;
    }

    display: block;
    width: 100%;
  }

  img {
    transform: rotate(90deg);
    width: 50px;
  }
}
