@import "src/custom.scss";






















































.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba($gold, 0.4);
  z-index: 10000000000;
  display: flex;
  justify-content: center;
  align-items: center;


  .box {
    width: 30%;
    overflow: hidden;
    position: relative;
    min-width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70%;
    //background-color: #fff;
    background-color: #fff;
    padding: 20px;
    @include mobile {
      //width: 100%;
      min-width: 100%;
    }
  }

  .text {
    color: #888;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    padding: 10px 20px;
  }

  a, button {
    //display: block;
    font-size: 16px;
    padding: 10px;
    font-weight: 700;
    width: calc(100% - 40px);
    //height: 32px;
    background-color: #fff;
    border: 1px solid $gold;
    color: $gold;
    margin: 5px 10px;
    cursor: pointer;
    display: inline-block;
  }
}
