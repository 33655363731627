@import "src/custom.scss";























































































.subpage {
  overflow: hidden;
  @include mobile {

    flex-direction: column;
    .info {
      order: 2;
      padding-top: 20px;
    }
    .bar {
      order: 1;
      height: 10px !important;
    }
  }
}

.button {
  margin-left: 100px;
  width: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 1.3em;
  margin-top: 30px;
  display: flex;
  @include mobile {
    margin-left: 0;
    padding: 0 30px 0 50px;
  }
  a {
  justify-content: space-between;
    padding: 15px 20px;
    color: $gold;
    border: 1px solid $gold;
    font-weight: bold;
    width: 100%;
    display: flex;
    &:hover {
      background-color: lighten($gold, 50%);
    }


  }
}

.textfeld {
  margin-left: 100px;
  width: 60%;
  text-align: left;
  font-size: 18px;
  line-height: 1.3em;
  @include mobile {
    margin-left: 0;
    width: 80%;
    font-size: 14px;
    padding: 0 30px 0 50px;
  }
}

.bg {
  width: 50%;
  background-size: cover;
  @include mobile {
    width: 100%;
    height: 40vh;
    padding-top: 100px;
    background-position: center 100px;
  }
}

.content {
  width: 50%;

  @include mobile {
    width: 100%;

  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bar {
    height: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .red {
      transition: background-color 0.5s ease;

      background-color: #fff;
    }

    .router-link-active {
      background-color: $red;

    }
  }
}
