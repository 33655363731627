@import "src/custom.scss";





























































































.header {
  height: 100px;
  width: 100%;
  left: 0;
  text-align: left;
  top: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dotted $gold;
  position: fixed;
  display: flex;
  background-color: #fff;
  z-index: 1000;

  &.sub {
    z-index: 2000;
  }

  @include mobile {
    height: 200px !important;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    &.home {
      height: 100px !important;

      .right {
        display: none;
      }
    }
  }

  .left {
    .start {
      @include mobile {

        width: 75%;
      }
    }
  }

  .right {
    display: flex;
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .pagelink {

    div {
      width: 100%;
      display: flex;
      align-items: center;

      justify-content: center;
      height: 100%;
    }

    font-weight: 300;
    display: flex;
    color: #fff;
    font-size: 40px;
    font-family: 'Benchnine', Avenir, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    height: 100px;
    width: 80%;
    @include mobile {
      width: 100%;
    }

    .cat {
      text-transform: capitalize;
      display: flex;
      font-size: 24px;

      .productmenutrigger {
        padding: 20px 0;
        height: 60px;
        border-left: 1px dotted white;

        &.active {
          background-color: lighten($gold, 10%);
        }

        img {
          height: 100%;
        }
      }
    }
  }

  .golden {
    background-color: $gold;
    cursor: pointer;
  }

  .nosubpath {

    opacity: 0;
    pointer-events: none;
    @include mobile {
      opacity: 1;
      pointer-events: all;
    }
    @include mobile {
      display: inline-flex;
    }
  }

  .menubutton {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
transition: background-color 0.2s ease;
    &:hover {
      background-color: rgba($gold, 10%);
    }  &.active {
      background-color: rgba($gold, 20%);
    }

    img {
      width: 50px;
    }
  }
}
