@import "src/custom.scss";










#app {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  background-color: $gold;

  font-size: 20px;
  @include laptop {
    font-size: 18px;
    line-height: 1.3em;
  }
  @include mobile {
    font-size: 16px;
    max-width: 100vw;
  }
  line-height: 1.4em;
}

body {
  margin: 0;
  overflow-x: hidden;
&.overlay {
  overflow: hidden;

}
}

a {
  text-decoration: none;
}

.chevron {
  height: 100%;

  &.left {
    transform: rotate(180deg)
  }
}

.subpage {
  position: absolute;
  min-height: calc(100vh - 100px);
  height: auto;
  top: 100px;
  display: flex;
  background-color: #fff;
  width: 100%;

  .infoheader {
    display: flex;
    margin-top: 20vh;
    align-items: center;
    @include mobile {
      margin-top: 0;
    }

    h2 {
      width: 70%;
      text-align: left;
      margin: 0px;
      color: $red;
      font-size: 70px;
      line-height: 1.1em;
      @include laptop {
        font-size: 40px;
      }
      @include mobile {
        font-size: 30px;

      }
    }

    h3 {
      margin: 0;
    }


  }
}

.arrow {
  font-size: 40px;
  height: 60px;
  width: 100px;
  @include laptop {
    height: 40px;
  }
  @include mobile {

    width: 50px;
  }

  a {
    height: 40px;
    padding-top: 15px;
    @include laptop {
      height: 30px;
      padding-top: 5px;
    }
    display: block;
    text-decoration: none;
    color: $red;
    @include mobile {

      height: 30px;
    }
  }
}

h1, h2, h3, h4 {
  font-family: $headfont;
  font-weight: 300;
  line-height: 1.2em;
}

h1 {
  background-color: $gold;
  color: #fff;
  width: 100%;
  font-weight: 300;
  font-size: 100px;
  @include laptop {
    font-size: 60px;
  }
  text-transform: uppercase;
  letter-spacing: -1px;

  &.light {
    font-weight: 300;
  }
}

h2 {
  color: $red;
  font-size: 60px;
  line-height: 1.2em;
  @include laptop {
    font-size: 40px;
  }
  margin: 10px 0;
  @include mobile {
    font-size: 30px;
  }
}

h3 {
  color: $red;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 1.2em;
  @include mobile {
    font-size: 14px;
  }

}

a.block {
  text-decoration: none;
  display: block;
  color: inherit;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.wp-video {
  max-width: calc(100%);
  width: 100%!important;
  text-align: center;
  video {
    max-width: calc(100% - 10px);
  }
}

ul{
  list-style-type: none;
}


/* benchnine-300 - latin */
@font-face {
  font-family: 'BenchNine';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('assets/fonts/benchnine-v9-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/benchnine-v9-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* benchnine-regular - latin */
@font-face {
  font-family: 'BenchNine';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/benchnine-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/benchnine-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* benchnine-700 - latin */
@font-face {
  font-family: 'BenchNine';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('assets/fonts/benchnine-v9-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('assets/fonts/benchnine-v9-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
