@import "src/custom.scss";




























.bottle {
  position: absolute;
  width: 100%;
  pointer-events: none;

  &.overlap {

    z-index: 1500;
    @include mobile {
      z-index: 999;
    }
  }

  height: 120vh;
  @include laptop {
    height: 100vh;
  }
  //background-color: yellow;
  img {
    height: 100%;
    transform-origin: center 45%;
    position: relative;
    @include mobile {
      //transform: rotate(0deg) !important;
      //margin-left: 30%;
      height: 100vh;
      top: 70px !important;
      left: 5px;
      transform-origin: center 35%;
    }
  }
}
