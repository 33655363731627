@import "src/custom.scss";





















footer {
  background-color: #fff;
  max-width: 100%;
  padding-top: 20px;
  width: 100%;
  .address {
    //width: 100%;
  padding: 20px 10px 50px;
  font-size: 14px;
    line-height: 1.3em;
    @include mobile {
      font-size: 13px;
    }
  }

  img {
    @include mobile {

    max-width: 100%;
    width: 100%;
    }
max-width: 40%;
  }
}
