@import "src/custom.scss";




















































































































.page {
  //max-height: 100vh;
  //position: absolute;

  transition: opacity 0.5s ease;
max-width: 100%;
  &.scrolled {
    opacity: 0;
    transition: opacity 0s ease;
  }

  .bottle {
    transition: transform 0.5s ease 0.5s;
    transform: translateY(0px);
  }

  &.scrolled {
    .bottle {
      transform: translateY(100px);
    }
  }

  &.hidden {
  }

  .page2 {
    //display: none;
  }

  &.hidden {
    max-height: calc(100vh - 100px);
    overflow: hidden;

    .page1 {
      display: none;
    }

    .page2 {
      display: none;
    }
  }
}

.page1 {
  padding-top: 100px;
  min-height: calc(100vh - 100px);
  height: auto;
  display: flex;

  align-items: stretch;

  .bg {
    width: 50%;
    position: fixed;
    left: 0;
    height: calc(100vh - 100px);
    //background-attachment: fixed;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    @include mobile {
      width: 100%;
      position: relative;

    }
  }

  .content {
    margin-left: 50%;
    height: auto;
    width: 50%;
    background-size: cover;
    background-position: bottom center;
    @include mobile {
      width: 100%;
      margin-left: 0;
    }
    @include desktop {
      background: $gold !important;
    }

    .vertical {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //height: 100%;
min-height: calc(100vh - 100px);
      .text {
        @include mobile {
          font-size: 14px;
        }

      }

      .arrowheader {

        img {
          height: 40px;
          @include mobile {
            height: 22px;
          }
        }
      }

      @include mobile {
        height: calc(100vh - 100px);
        //justify-content: flex-end;
      }

      h1 {
        margin-top: 100px;
        @include mobile {
          margin: 0;
          visibility: hidden;
        }
      }

      .box {
        margin: 50px;
        background-color: beige;
        padding: 10px;
        cursor: pointer;

        @include mobile {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }

        .inner {
          border: 1px solid black;
          padding: 10px;
          @include mobile {
            border-right: none;

          }

          h2 {
            color: $gold;

          }
        }
      }
    }
  }

  .bg {
    @include mobile {
      display: none;
    }
  }
}

.simplecontent {
  height: auto;
  font-size: 14px;
  line-height: 1.4em;

  h1, h2, h3, h4 {
    color: #fff;
  }

  h4 {
    font-family: Georgia, Avenir, Helvetica, Arial, sans-serif;
    font-size: 24px;
    line-height: 1.4em;
    font-weight: 400;
    @include mobile {
      font-size: 18px;
    }
  }

  h2 {
    font-weight: 300;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.zitat {
  font-size: 60px;
  @include mobile {
    font-size: 40px;
  }
  line-height: 1.2em;
  color: #fff;
  padding: 0 10px;
  margin: 20px 0;
  font-family: 'Benchnine', Avenir, Helvetica, Arial, sans-serif;
}

.drop {
  img {
    max-height: 100%;
    height: 150px;
  }
}

.page3 {
  min-height:90vh;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
}

