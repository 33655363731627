@import "src/custom.scss";









































































































































.bg {
  width: 50%;
  background-size: calc(100vh - 100px);
  background-position: center 10px;
  background-repeat: no-repeat;

  @include mobile {
    background: none !important;
    width: 45%;

  }
}

.praline {
  display: none;
}

.schokoladiges-fruchtiges {
  .bg {
    background-position: center 100px;
  }

  .twocols {
    @include mobile {
      display: flex;
      align-items: flex-start;
      table {
        width: 60%;
      }
      button {
        width: 40%;
      }
    }
  }

  .praline {
    @include mobile {
      display: block;
    }
  }
}

@include mobile {

  .schokoladiges-fruchtiges {
    padding-bottom: 40px;
    background-position: center bottom !important;
    background-size: 130% !important;
    background: none !important;

    .bg {
      width: 10%;

    }

    .content {
      width: 80%;
      margin-left: 5vw;

      .infoheader {
        margin-top: 20px;
      }
    }
  }
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  @include mobile {
    //width: 45%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  text-align: left;

  .textfeld {
    width: 70%;
    text-align: left;
    margin: 10px 0;
    @include mobile {
      width: 90%;

      padding-right: 10px;
      font-size: 12px;
      line-height: 1.3em;
    }
  }
}

.infoheader {
  margin-top: 10vh;
  position: relative;
  @include mobile {
    margin-top: 20px;
  }

  h2 {
    width: 100%;
    @include mobile {
      font-size: 28px;
    }
  }
}

.nahrwerte {

  @include mobile {
    background-color: rgba(#fff, 0.7);
    padding: 0;
    font-size: 12px;

  }

  table {
    margin-top: 10px;
  }

  td {
    padding: 0 20px 0 0;

    &.desc {
      color: $gold;
    }
  }

  button {
    background-color: #fff;
    text-decoration: none;
    margin: 10px 0 0;
    font-weight: 700;

    a {
      padding: 10px;
      display: block;
      color: $gold;
      font-size: 22px;
      @include mobile {
        font-size: 16px;
        padding: 5px;
      }
    }

    border: 1px $gold solid;
  }
}

.products {
  flex-wrap: wrap;
  //margin-top: 100px;

  @include mobile {
    background-size: 150%;
    background-position: center 100px;
    padding-top: 100px;
    background-repeat: no-repeat;
  }

  @include desktop {
    background: #fff !important;
  }

  .arrow {
    position: absolute;
    top: 10vh;
    @include mobile {
      top: calc(10vh + 90px);

    }

    &.left {
      left: 5vw;
      @include mobile {
        left: 0;
      }
    }

    &.right {
      right: 5vw;
      @include mobile {
        right: 0;
      }
      z-index: 150;

    }
  }
}
