@import "src/custom.scss";








































































.page {

}

h1 {
  line-height: 1.2em;
  font-size: 80px;
  height: 80vh;
  padding-top: 15vh;
  @include mobile {
    font-size: 40px;
    height: auto;
    padding-top: 0;
  }
}

.nomobile {
  @include mobile {
    display: none;
  }
}

.mobile {
  display: none;
  @include mobile {
    display: block;
  }
}

.drop {
  img {
    width: 200px;
  }

  margin-left: 20%;
  width: 60%;
  font-size: 20px;
  line-height: 1.4em;
  @include mobile {
    width: 90%;
    margin-left: 5%;
  }
}

.goldenheader {
  background-color: $gold;
  //min-height: 100vh;
  &.mobile {
    padding-top: 100px;
    padding-bottom: 10px;

  }
}

.start {
  background-color: #bbb;
  //max-height: 200vh;
  flex-direction: row;
  display: flex;
  align-items: stretch;
  padding-top: 90px;

  @include mobile {
    flex-direction: column;
    padding-top: 0;
  }

  .goldenheader {
    .head {
      height: 80vh;
      padding: 0 15%;
      @include mobile {
        height: auto;
        padding: 0;
      }
    }
    order: 2;
    width: 50%;
    @include mobile {
      width: 100%;
      //height: 40vh;
      order: 2;
    }
    //height: 100%;
  }
  .pic {
    background-position: -2% 100px;
    background-image: url('../assets/img/Brennerei_Hauptmotiv_01_Streuobstwiese_Baum.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    order: 1;
    background-color: $gold;
    background-size: 55% auto;
    @include mobile {
      background-size: cover;
      background-attachment: unset;
      background-position: 0 0;
      width: 100%;
      height: 80vh;
    }
    width: 50%;


  }
}
