@import "src/custom.scss";


















































.menu {
  max-height: calc(100vh - 100px);
  overflow: auto;
  width: calc(50%);
  background-color: #fff;
  border-top: 2px solid #fff;
  position: fixed;
  top: 100px;
  z-index: 100000;
  right: 0;
  @include mobile {
    width: 100%;
    right: 0;
    top: 100px !important;
  }

  a {
    background-color: $gold;
    transition: background-color 0.2s ease;
    color: #fff;
    text-align: center;
    position: relative;
    text-transform: uppercase;
    font-family: 'Benchnine', Avenir, Helvetica, Arial, sans-serif;
    font-size: 30px;
    padding: 15px 5px;
    border-bottom: 1px solid #fff;
    @include mobile {
      padding: 18px 5px;
      font-size: 30px;
    }

    &.router-link-exact-active {
      //background-color: #fff;
      background-color: lighten($gold, 10%);
      color: #fff;
    }

    &:hover {
      //background-color: #fff;
      background-color: lighten($gold, 15%);
      color: #fff;
    }

  }
.switch {
  position: absolute;
  height: 58px;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #fff;
  width: 100px;
  cursor: pointer;
  &.active {
    background-color: lighten($gold, 15%);
  }
  img {
    //height: 60%;
    height: 60%;
  }
}
  .subsub {
    display: flex;
    background-color: $gold;
    justify-content: space-around;
    border-bottom: 1px solid #fff;
    //flex-wrap: wrap;
    a {
      text-transform: capitalize;
      border-right: 1px solid #fff;
      border-bottom: none;
      //margin: 10px;
      padding: 15px 10px;
      font-size: 20px;
      width: 100%;

      &:last-child {
        border: none;
      }
    }

  }
}
