@import "src/custom.scss";
































.page2 {
  padding-top: 100px;
  height: calc(100vh - 100px);
  background-color: $gold;
  color: #fff;
  overflow: hidden;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
  flex-direction: column;





  @include mobile {
    background-size: contain;
  }
}
